<template>
  <div class="container-fluid feeds">
    <div class="col-sm-12 col-md-12 feeds">
      <Loader v-if="load"/>
      <div class="div-flex" v-if="!load">
        <the-sidebar class="hidden-sm hidden-xs"/>
        <div class="feed">
          <div class="contain-overflow">
            <div class="more-match-markets">
              <div class="single-match-details" v-if="!load">
                <div class="match-header">
                  <span class="" @click="$router.go(-1)">
                    <i class="icofont-rounded-left"></i>
                  </span>
                  <p class="league">
                    <span v-if="$i18n.locale == 'en'">{{ display_m.en_name }}</span>
                    <span v-if="$i18n.locale == 'fr'">{{ display_m.fr_name }}</span> 
                     {{ game.tournament }}
                  </p>
                  <div style="width: 10px;"></div>
                </div>
                <div class="match-info">
                  <div class="">
                    <span><i class="icofont-cop-badge"></i></span>
                    <span>{{ game.home }}</span>
                  </div>
                  <div class="">
                    <span>vs</span>
                    <span>{{ game.scheduled | moment('HH:mm - D/MM')  }}</span>
                  </div>
                  <div class="">
                    <span><i class="icofont-cop-badge"></i></span>
                    <span>{{ game.away }}</span>
                  </div>
                </div>                
              </div>
              <div class="single-match-details" v-if="$route.params.sportId == 10">
                <ul class="list-inline cat">
                  <li>
                    <span :class="{ 'select' : marketIndex == 0 }" @click="selectCat(0)">{{ $t('feeds.all-markets')}}</span>
                  </li>
                  <li>
                    <span :class="{ 'select' : marketIndex == 1 }" @click="selectCat(1)">{{ $t('feeds.main')}}</span>
                  </li>
                  <li>
                    <span :class="{ 'select' : marketIndex == 7 }" @click="selectCat(7)">{{ $t('feeds.minutes')}}</span>
                  </li>
                  <li>
                    <span :class="{ 'select' : marketIndex == 2 }" @click="selectCat(2)">{{ $t('feeds.first-half')}}</span>
                  </li>
                  <li>
                    <span :class="{ 'select' : marketIndex == 3 }" @click="selectCat(3)">{{ $t('feeds.goals')}}</span>
                  </li>
                  <li>
                    <span :class="{ 'select' : marketIndex == 4 }" @click="selectCat(4)">{{ $t('feeds.player')}}</span>
                  </li>
                  <li>
                    <span :class="{ 'select' : marketIndex == 5 }" @click="selectCat(5)">{{ $t('feeds.combo')}}</span>
                  </li>
                  <li>
                    <span :class="{ 'select' : marketIndex == 6 }" @click="selectCat(6)">{{ $t('feeds.others')}}</span>
                  </li>
                </ul>
              </div>
              <div class="match-markets">
                <div v-frag v-for="(market,value) in match" :key="'dis'+value">                                     
                  <div v-for="(mkt,i) in display_m.markets" :key="'x' + i">
                    <template v-if="(market.key == mkt.key)">
                      <span class="market-title" data-toggle="collapse" aria-expanded="true" :data-target="('#demo'+market.id)">
                        <i class="icofont-star-shape"></i> {{ marketTranslate(market) }}
                        <i class="icofont-caret-right pull-right"></i>
                      </span>
                      <div class="collapse in" :id="('demo'+market.id)">
                        <div class="market-body" :class="{ 'three-markets':mkt.selections.length > 2,'two-markets':mkt.selections.length == 2 }">
                          <div v-frag v-for="(select,x) in mkt.selections" :key="x">
                            <template v-if="match.find( x => x.key === mkt.key).odds.length > 0"> 
                              <div v-frag v-for="(odd,y) in match.find( x => x.key == mkt.key).odds" :key="y">
                                <div 
                                  v-if="(select.id == odd.outcomeId)"
                                  @click="addToBetslip(odd,game,match.find( g => g.marketId === market.marketId && g.key === market.key))"                 
                                  :class ="{ 'selected' : odd.id == game.selected_odd_id || sharedBet.find(x => x.odd_id == odd.id) }"
                                >
                                  <span>{{ marketSpecifier(odd) }}</span>
                                  <span class="pull-right">{{ parseFloat(odd.odds).toFixed(2) }}</span>
                                </div>
                              </div>
                            </template>
                            </div>                                                                  
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-frag v-for="(market,ind) in match" :key="market.id">
                  <template v-if="marketIds.indexOf(market.key) === -1">
                    <span class="market-title" data-toggle="collapse" aria-expanded="false" :data-target="('#demo'+market.id)">
                      <i class="icofont-star-shape"></i> {{ marketTranslate(market) }} 
                      <i class="icofont-caret-right pull-right"></i>
                    </span>
                    <div class="collapse" :id="('demo'+market.id)" :class="{ 'in': ind == 0 }">
                      <div class="market-body other" :class="{ 'three-markets':market.odds.length > 2,'two-markets':market.odds.length == 2 }">
                        <div v-frag v-for ="(odd,x) of match.find( od => od.id == market.id ).odds" :key="x">
                          <div @click="addToBetslip(odd,game,match.find( g => g.marketId === market.marketId && g.key === market.key))"                 
                                    :class ="{ 'selected' : odd.id == game.selected_odd_id || sharedBet.find(x => x.odd_id == odd.id) }">
                            <span>{{ marketSpecifier(odd) }}</span>
                            <span class="pull-right">{{ parseFloat(odd.odds).toFixed(2) }}</span>
                          </div>
                        </div>            
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <the-betslip class="hidden-sm hidden-xs home-bet"/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import BetslipService from "@/services/betslip";
import Loader from "@/components/sections/loader.vue";
import { getCountryISO2 } from "@/utils/utility";

export default {
  name: 'single-match',
  data() {
    return {
      specifier: [],
      display: [2,7202],
      main:[2,3,295,7136,6911,7202,7079,25,259,60,59,7591],
      first_half:[6832,170,10459,9536,7769,12502,14329,14330,9535],
      goals:[91, 253, 295, 362, 6911, 7354, 7809],
      player:[12, 13],
      combo:[14312,13707,8239,7076,9498,10849,10850,14309,9536],
      minutes:[11108,10554,10453,11109,11110,10479,10480],
      others:[105, 7591, 122, 82, 8863, 8864, 14308, 14415],
      load: false,
      marketIndex:0
    }
  },
  computed:{
    ...mapGetters({
      game: 'single_match',
      display_m: 'markets',
      sharedBet:'sharedBet'
    }),
    marketIds() {
      let temp = [];
      this.display_m.markets.map( x => temp.push(x.key))
      return temp;
    },
    match() {
      let temp;
      switch (this.marketIndex) {
        case 0:
          temp = this.game.markets;
          break;
        case 1:
          temp = (this.game) ? this.game.markets.filter( x => this.main.includes(Number(x.marketId)) ) : [];
          
          break;
        case 2:
          temp = (this.game) ? this.game.markets.filter( x => this.first_half.includes(Number(x.marketId)) ) : [];
          break;
        case 3:
          temp = (this.game) ? this.game.markets.filter( x => this.goals.includes(Number(x.marketId)) ) : [];
          break;
        case 4:
          temp = (this.game) ? this.game.markets.filter( x => this.player.includes(Number(x.marketId)) ) : [];
          break;
        case 5:
          temp = (this.game) ? this.game.markets.filter( x => this.combo.includes(Number(x.marketId)) ) : [];
          break;
        case 6:
          temp = (this.game) ? this.game.markets.filter( x => this.others.includes(Number(x.marketId)) ) : [];
          break;
        case 7:
          temp = (this.game) ? this.game.markets.filter( x => this.minutes.includes(Number(x.marketId)) ) : [];
          break;
        default:
          break;
      }
      return temp;
    }
  },
  components: {
    Loader,
    // odd
  },
  methods: { 
    addToBetslip: function(odds, match, market) {  
      let obj= {
        id:match.id,
        home: match.home,
        away:match.away,
        sportId: match.sportId,
        scheduled: match.scheduled,

        market: {
            id:market.marketId,
            mid:market.id,
            mhandicap: market.handicap,
            mname: market.name,
            oddId: odds.id,
            outcome: odds.outcomeId,
            pick: odds.name,
            status: odds.status,
            odd: odds.odds,
            key: market.key,
            oddKey:odds.key,
            type: 'prematch'
        }
      } 

      let check = this.sharedBet.find(x => x.match_id == match.id);
      if(check)
      {
        this.$store.dispatch('removeSharedBets',match.id);
      }
      // add bet to slip
      BetslipService.addBetslip(obj);
    },
    marketTranslate: function(mkt) { 
      if( this.$i18n.te("markets." + mkt.marketId) ) 
      {              
        let str = this.$i18n.t("markets." + mkt.marketId,"en");
        let fstr = this.$i18n.t("markets." + mkt.marketId,"fr");
        
        if(this.$i18n.locale != "en")
        { 
          let mt = '';                   
          if(mkt.handicap != 'n/a') {
            mt = fstr + ' ' + mkt.handicap ;
          } else {
            mt = fstr;
          }
           
          return mt.toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
        } else if(str) {
          let mt = '';
          if(mkt.handicap != 'n/a') {
            mt = str + ' ' + mkt.handicap ;
          } else {
            mt = str;
          }
          return mt;
        } else
        {
            return mkt.name.toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
        }                
      } 
      else if(this.$i18n.te("markets." + mkt.key)) 
      {
        return this.$i18n.t("markets." + mkt.key).toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
      }
      else 
      {
        return mkt.name.toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
      }
    },
    marketSpecifier: function (odd) {
      if( this.$i18n.te("markets." + odd.name.toLowerCase()) )
      {
        return  this.$i18n.t("markets." + odd.key).toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
      }
      else if(this.$i18n.te("markets." + odd.key))
      {
        return  this.$i18n.t("markets." + odd.key).toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
      }
      else
      {
        return odd.name
      }
    },
    thisMounted: async function() {
      this.load = true;
      this.$Progress.start();
      await this.$store.dispatch("fetch_single_match",{
        id:  this.$route.params.id
      })
      await this.$store.dispatch("get_markets",{
        id:this.$route.params.sportId
      })
      this.load = false;
      this.$Progress.finish();
    },
    iso: function (code) {
      const res = getCountryISO2(code,'sidebar')
      if(res) {
          return res
      } else {
          return {
              "code": "FM",
              "code3": "DEF",
              "fr_name": code,
              "name": code,
              "number": "248"
          }
      }
    },
    selectCat(i) {
      this.marketIndex = i;
    }
  },
  created () {
    this.subscription = BetslipService.getAddedBetslip().subscribe( () => {
      this.$store.dispatch("update_games_state");
      this.$store.dispatch("modify_single_state");
    });

    this.mysubscription = BetslipService.getRemovedBetslip().subscribe( () => { 
      this.$store.dispatch("remove_games_state");
      this.$store.dispatch("modify_single_state");
    });
  },
  mounted(){
    this.thisMounted()  
  },
}
</script>
<style scoped>
  .feeds {
    padding: 4px;
  }
  .div-flex {
    display: flex;
  }
  .div-flex .feed {
    width: 100%;
  }
  .div {
    cursor: pointer;
  }
  div span:nth-child(2){
    font-weight: normal;
  }
  .cat {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .cat span {
    display: inline-block;
    background-color: var(--bg-blue);
    padding: 4px 10px;
    border-radius: 15px;
    cursor: pointer;
  }
  .cat span.select {
    background-color: var(--bg-orange);
  }
</style>